/* Aggiungi stili CSS secondo necessità */
body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0; /* Aggiunto per rimuovere il margine predefinito */
  padding: 0;
}

h2 {
  color: #333;
  margin-bottom: 20px;
  text-align: left;
}

#radar-chart-container {
  max-width: 100%;
  margin-bottom: 20px;
}

#sorted-list-container {
  margin: 0px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

li.selected {
  background-color: #4caf50;
  color: #fff;
}

button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

button:hover {
  background-color: #45a049;
}

table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 15px;
  text-align: left;
}

th {
  background-color: #4caf50;
  color: #fff;
}

td {
  background-color: #f9f9f9;
}

p {
  margin-top: 20px;
}

.search-bar {
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

nav {
  background-color: #333;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: 0;
}

nav a {
  color: #fff;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

nav a:hover {
  background-color: #ddd;
  color: #333;
}

#contenitore {
  margin: 20px;
}

.not-available {
  background-color: #ff7373; /* Sostituisci con il colore desiderato */
}

.popup {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  color: #ff7373;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 0px solid #000000;
  border-radius: 10px;
  padding: 10px;
  font-weight: bold;
  text-align: center;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

/* Stile generale del dropdown */
select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  outline: none;
}

/* Stile del dropdown quando è aperto */
select:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

/* Aggiungi queste regole al tuo foglio di stile CSS */

/* Allinea il nome del giocatore a sinistra */
#sorted-list-container li b {
  text-align: left;
  display: inline-block;
  width: 150px; /* Puoi regolare la larghezza in base alle tue esigenze */
}

/* Allinea i dati (medie) a destra */
#sorted-list-container li span {
  text-align: right;
  display: inline-block;
  width: 100px; /* Puoi regolare la larghezza in base alle tue esigenze */
}

/* Stile del dropdown */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Contenuto del dropdown */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Mostra il dropdown quando passi sopra */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Stile degli elementi all'interno del dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Cambia il colore di sfondo quando l'elemento del dropdown è selezionato */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Stile per l'opzione di selezione attiva */
.dropdown-content a.active {
  background-color: #4caf50;
  color: white;
}

.card {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1000;
}

.card h3 {
  margin-top: 0;
}

.card button {
  margin-top: 20px;
  background-color: #ff7373;
  border: none;
  padding: 10px 20px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.chart-icon {
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: 10px;
}

.performances {
  margin-top: 20px;
}

.performances h4 {
  margin-bottom: 10px;
}

.performances ul {
  list-style-type: none;
  padding: 0;
}

.performances li {
  margin-bottom: 5px;
}
